<template>
  <div class="land-container">
    <!-- 结束会议弹窗 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :visible.sync="endMeetElDialog"
    >

      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{ endMeetElDialogTitle }}</div>
      </div>

      <div class="dialog-content">
        <div class="content-item">
          <div class="left">{{$t('login.meetNumber')}}</div>
          {{ forceEndMeetInfo.conferenceNo }}
        </div>
        <div class="content-item">
          <div class="left">{{$t('login.meetTheme')}}</div>
          {{ forceEndMeetInfo.title }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="forceEndMeetHandle">{{$t('login.end')}}</el-button>
        <el-button @click="goBackIndex">{{$t('login.noEnd')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="passwordDialog"
      @close="passwordDialogCloseHandle"
    >
      <div class="dialog-title" style="marginBottom: 15px;">
        <div class="text">
          {{ $t('login.pleasePws') }}
        </div>
      </div>

      <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm">
        <el-form-item prop="pwd">
          <el-input :placeholder="$t('login.meetNo')" clearable v-model="passwordForm.pwd"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialog = false">
          {{ $t('login.cancel') }}
        </el-button>

        <el-button type="primary" @click="passwordSubmit">
          {{ $t('login.sure') }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 是否可以预约会议和个人会议提前进入 -->
    <el-dialog
      custom-class="advanceElDialog"
      :width = "$i18n.locale === 'en-US' ? '570px' : '420px'"
      :visible.sync="advanceDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="advanceDialogCloseHandle"
    >
      <div class="axiosTitle">{{ advanceErrorData.content }}</div>

      <div class="axiosItem" v-if="advanceErrorData.beginTime && advanceErrorData.type === 1">
        <div class="axiosItemTitle">{{ $t('login.startTime') }}</div>
        <div class="axiosItemValue">{{ advanceErrorData.beginTime }}</div>
      </div>

      <div class="axiosItem">
        <div class="axiosItemValue mutRowsTitle">{{ advanceErrorData.title }}</div>
      </div>
      <div class="axiosItem">
        <div class="axiosItemValue2">{{$t('login.ifMain')}}</div>
      </div>

      <span slot="footer" class="dialog-footer" style='display:inline-block'>
        <el-button @click="goLoginPage">{{$t('home.signIn')}}</el-button>
        <el-button type="primary" @click="advanceDialog = false">{{$t('login.leaveMeet')}}</el-button>
      </span>
    </el-dialog>

    <!-- 主持人正在开启另外的会议 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="waitHostDialog"
      @close="waitHostDialogCloseHandle"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">
          {{ waitHostErrorData.tips }}
        </div>
      </div>

      <div class="dialog-content">
        <div class="content-item">
          <div class="left">{{$t('login.meetNumber')}}</div>
          {{ waitHostErrorData.conferenceNo }}
        </div>
        <div class="content-item">
          <div class="left">{{$t('login.meetTheme')}}</div>
          {{ waitHostErrorData.title }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="waitHostDialog = false">
          {{ $t('login.leaveMeet')}}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import joinErrorMixins from '@/mixins/joinError.mixins'
import dayjs from 'dayjs'
import { setMeetInfo, setToken } from '@/utils/auth'
import { createConference, joinConference } from '@/api/meet'

export default {
  mixins: [joinErrorMixins],
  data() {
    return {
    }
  },
  created() {
    this.enterMeetHandle()
  },

  methods: {
    async enterMeetHandle() {
      const { loadData } = this.$route.query
      let isLock = false

      if (loadData) { // 存在loadData
        const meetInfo = JSON.parse(decodeURIComponent(loadData))

        console.log(meetInfo)

        // 存储token
        setToken(meetInfo.ybToken)

        // 会议号
        let conferenceNo = meetInfo.conferenceNo

        if (meetInfo.type === 2) { // 即时会议，这时候需要调用create接口
          // 创建会议
          const nowTime = dayjs(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')
          const createParam = {
            agendaPermission: meetInfo.agendaPermission, // 会议议程权限 0：所有人可见 1：仅主持人可见
            allowEarlyEntry: meetInfo.allowEarlyEntry, // 是否允许提前进入 1：是；0否；
            allowSelfUnmute: meetInfo.allowSelfUnmute, // 成员加入会议静音 1：是；0否；
            beginTime: nowTime, // 开始时间 2021-04-29 15:06:03
            endTime: nowTime, // 结束时间 2021-04-29 15:06:03

            muteJoinMeeting: meetInfo.muteJoinMeeting, // 允许成员自我解除静音;1是；0：否

            password: meetInfo.password, // 入会密码
            passwordEnable: meetInfo.passwordEnable, // 是否开启入会密码 0 关闭，默认；1 开启
            playTips: meetInfo.playTips, // 成员加入会议时播放提示音1：是；0否；

            title: meetInfo.realName + this.$t('seeting.personMeet'), // 会议名称

            type: 2, // 1:预约会议 2:即时会议

            useSelfConference: 1, // 是否使用个人会议号  1:是 0：否 这里写死就行
          }

          try {
            const createData = await createConference(createParam)

            console.log('create---hiyi')
            console.log(createData)

            // 即时会议，重新对conferenceNo进行赋值
            conferenceNo = createData.conferenceNo
          } catch (error) {
            isLock = true

            this.handleEndMeetError(
              error,
              this.enterMeetHandle
            )
          }
        }

        if (!isLock) {
          console.log('join放行--------')
          this.handleJoin({
            meetInfo,
            conferenceNo
          })
        } else {
          console.error('不执行--------')
        }
      }
    },

    async handleJoin(data) { // 加入会议
      const { meetInfo, conferenceNo } = data

      try {
        // 加入会议
        const joinParam = {
          conferenceNo: conferenceNo,
          closeConference: true,
          userName: meetInfo.realName,
        }

        if (this.passwordForm.pwd) {
          joinParam.password = this.passwordForm.pwd
        }

        console.log(joinParam)

        const resData = await joinConference(joinParam)

        console.error('join--会议--')
        console.log(resData)

        const { conference, userid, roomid } = resData

        // 入会失败的状态清理
        this.clearErrorState()

        setMeetInfo(
          resData["X-Conference-Token"],
          resData["X-Channel-Token"]
        )

        // 跳转
        this.$router.replace({
          name: "meeting",
          params: { userID: userid },
          query: {
            roomID: roomid, // 房间id
            conferenceNo: conference.conferenceNo, // 会议号
            conferenceId: conference.id, // 会议id
            userName: encodeURIComponent(meetInfo.realName), // 展示名
          }
        })
      } catch (error) {
        console.error('执行111join----error----')

        this.joinErrorHandle(error, data)
      }
    },

    goBackIndex() {
      this.endMeetElDialog = false

      this.$router.replace({
        name: 'index'
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
