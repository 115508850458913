import { endConference } from '@/api/meet'

export default {
  data() {
    return {
      endMeetElDialog: false,
      endMeetElDialogTitle: '',
      forceEndMeetInfo: {},
    }
  },

  methods: {
    handleEndMeetError(error, cb, params) {
      const { code, msg, data } = error

      this.handleNetworkError(error)

      if (code === 60010) {
        this.forceEndMeetInfo = {
          conferenceNo: data.conferenceNo,
          title: data.title,
          roomId: data.roomId,
          cb,
          params
        }

        this.endMeetElDialogTitle = msg
        this.endMeetElDialog = true
      }
    },

    //网络错误弹窗
    handleNetworkError(error, callbackBeforeClose) {
      if (error.message && error.message === 'Network Error') {
        const describe = vm.$t('meeting.netWorkBad')
        const tips = vm.$t('login.tips')
        vm.$store.commit("meet/updateGlobalMeetState", {
          meetDialogInfo: {
            tips,
            describe,
            callbackBeforeClose
          }
        })
        return
      }
    },

    async forceEndMeetHandle() { // 强制结束会议
      try {
        const { roomId, cb, params } = this.forceEndMeetInfo

        await endConference({
          id: roomId
        })

        this.endMeetElDialog = false

        console.error('强制结束会议后，执行原入房函数')
        console.log(params)

        cb && cb.call(this, params)
      } catch (error) {
        console.log(error)
        this.endMeetElDialog = false
      }
    }
  }
}