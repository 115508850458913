import endMeetMixins from '@/mixins/endMeet.mixins'
import { removeUserInfo } from '@/utils/auth'

export default {
  mixins: [endMeetMixins],

  data() {
    return {
      passwordDialog: false,
      passwordForm: {
        pwd: ''
      },
      passwordRules: {
        pwd: [
          { required: true, message: '请输入会议密码', trigger: 'blur' },
          { min: 4, max: 6, message: '长度在 4 到 6 个字符', trigger: 'blur' }
        ]
      },

      advanceTimer: null,
      advanceDialog: false,
      advanceErrorData: {},

      waitHostTimer: null,
      waitHostDialog: false,
      waitHostErrorData: {},
    }
  },

  methods: {
    clearErrorState() {
      // 入会失败的状态清理
      this.passwordDialog = false
      clearInterval(this.advanceTimer)
      clearInterval(this.waitHostTimer)
    },

    advanceDialogCloseHandle() {
      console.log('advance关闭---');
      clearInterval(this.advanceTimer)
    },
    goLoginPage() {
      clearInterval(this.advanceTimer)
      this.advanceDialog = false
      removeUserInfo()
      this.$router.push("/login")
    },

    waitHostDialogCloseHandle() {
      console.log('wati关闭---');
      clearInterval(this.waitHostTimer)
    },

    passwordDialogCloseHandle() {
      this.passwordForm.pwd = ''
    },
    passwordSubmit() {
      this.$refs['passwordForm'].validate((valid) => {
        if (valid) {
          this.handleJoin()
        } else {
          return false;
        }
      });
    },
    
    joinErrorHandle(error, data) {
      console.error('joinErrorHandle----')
      console.log(error,data)

      this.handleEndMeetError(
        error,
        this.handleJoin,
        data
      )

      // 60005 60011 两个都要进行轮询，并且只有join的时候会触发
      if (error.code === 60006) { // 请输入密码
        this.passwordDialog = true
      } else if (error.code === 60012) { // 输入密码错误
        this.$totast(this.$t('login.pleaseTwo'))
      } else if (error.code === 60011) {
        this.waitHostDialog = true
        this.waitHostErrorData = {
          tips: error.msg,
          ...error.data
        }

        this.waitHostTimer = setTimeout(() => {
          this.handleJoin(data);
        }, 15000);
      } else if (error.code === 60005) {
        this.advanceDialog = true
        this.advanceErrorData = {
          content: error.msg,
          ...error.data
        }

        this.advanceTimer = setTimeout(() => {
          this.handleJoin(data);
        }, 15000);
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.advanceTimer)
    clearInterval(this.waitHostTimer)
  },
}